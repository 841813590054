/* This File sets the perameters for div things size and page order etc*/
.title {
  background-color: rgb(68, 179, 255);
  color: white;
  padding-top: 10px;
  font-size: 60px;
  padding-bottom: 20px;
}

.container {
    height: 105vh;
    position: relative;
    z-index: -2;
    top: -230px;
  }
  
  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 500px 100vw;
    border-color: transparent transparent #5f5fc4 transparent;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
 
  
  .circle {
    width: 300px;
    height: 300px;
    background-color: #5f5fc4;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 150px);
    top: -70px;
    z-index: 2;
  }
  
  .square {
    width: 200px;
    height: 200px;
    background-color: #7e7edc;
    transform: rotate(45deg);
    position: absolute;
    right: 50px;
    bottom: calc(50% - 100px);
    z-index: 2;
  }
  

  .background {
    background: linear-gradient(90deg, hwb(193 0% 0%) 0%, #fe92f5 100%);
    /*background-color: #001C31;*/
    height: 160vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
  }
  

  h1 {
    background: none;
    height: 10vh;
    position: relative;
    top: -50px;
    box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5),
    0px 2px 4px rgba(0, 255, 255, 0.5), 0px 4px 8px rgba(0, 255, 255, 0.5),
    0px 8px 16px rgba(0, 255, 255, 0.5);
    
    text-shadow: 0 0 10px rgba(204, 0, 255, 0.8),
      0 0 20px rgba(255, 255, 255, 0.8), 0 0 22px rgba(255, 255, 255, 0.8),
      0 0 40px rgba(68, 179, 255, 0.8), 0 0 60px rgba(68, 179, 255, 0.8),
      0 0 80px rgba(68, 179, 255, 0.5), 0 0 100px rgba(68, 179, 255, 0.5),
      0 0 140px rgba(68, 179, 255, 0.5), 0 0 200px rgba(68, 179, 255, 0.5);
    
  }

  .App a img {
    height: 60px;
    width: 217px;
    
    --button-bg-color: #5F7FFF;
    --button-font-family: Cookie;
    --text-color: #ffffff;
    --button-color: #FFDD00;
    --button-outline-color: #000000;
    margin-left: auto;
  }

  .coffee-button {
    display: block;
    position: relative;
    z-index: 1;
  }

  a {
    position: absolute;
    top: -170px;
    left: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 0 vh;
  }

  footer {
    background-color: #001C31; /* #001C31 #7084e7*/
    color: #fff;
    text-align: center;
    padding: 1rem;
    margin-top: -440px;
    
  }

  body {
    overflow-x: hidden;
  }

  .submit-button {
    background-color: #44B3FF;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    position: absolute;
    top: 320px;
    left: 690px;
  }
  
  .submit-button:hover {
    background-color: #2c86c8;
  }

  textarea {
    resize: none;
    overflow: hidden;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    min-height: 50px;
    transition: height 0.3s ease-in-out;
    position: relative;
    top: -10px;
    
  }
  
    
  .textbox {
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    top: -540px;
    left: 420px;
    
    box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5),
    0px 2px 4px rgba(0, 255, 255, 0.5), 0px 4px 8px rgba(0, 255, 255, 0.5),
    0px 8px 16px rgba(0, 255, 255, 0.5);
    background-color: #fe92f5;
  
  }
    
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    position: relative;
    top: -30px;
    
  }
    
  p {
    font-size: 1rem;
    line-height: 1.5;
  }


  .orb {
    animation: spin 4s linear infinite;
    width: 300px;
    height: 300px;
    margin: auto;
    margin-top: 140px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px #fff, inset 20px 0 60px violet, inset -20px 0 60px #0ff, inset 20px 0 300px violet, inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 60px violet, 10px 0 60px #0ff;
    position: relative;
    top: -70px;
    z-index: 3;
  }
  
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  

  @keyframes neonGlow {
    0% {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
        0 0 20px rgba(255, 255, 255, 0.8), 0 0 22px rgba(255, 255, 255, 0.8),
        0 0 40px rgba(66, 220, 219, 0.8), 0 0 60px rgba(66, 220, 219, 0.8),
        0 0 80px rgba(66, 220, 219, 0.5), 0 0 100px rgba(66, 220, 219, 0.5),
        0 0 140px rgba(66, 220, 219, 0.5), 0 0 200px rgba(66, 220, 219, 0.5);
    }
    100% {
      text-shadow: 0 0 2px rgba(255, 255, 255, 0.8),
        0 0 8px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.8),
        0 0 20px rgba(66, 220, 219, 0.8), 0 0 30px rgba(66, 220, 219, 0.8),
        0 0 40px rgba(66, 220, 219, 0.8), 0 0 50px rgba(66, 220, 219, 0.5),
        0 0 80px rgba(66, 220, 219, 0.5);
    }
  }

  .response {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    top: -180;
  }















  .container3 {
    width: 20%;
    max-width: 800px;
    height: 294px;
    margin: 0 auto;
    position: relative;
    top: -490px;
    background:none;
    box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5),
    0px 2px 4px rgba(0, 255, 255, 0.5), 0px 4px 8px rgba(0, 255, 255, 0.5),
    0px 8px 16px rgba(0, 255, 255, 0.5);
 
  }
  
  .compare-slider {
    margin-top: 50px;
    border: 1px solid #ccc;
  }
  
  .ReactCompareSlider-handle {
    background-color: #fff;
    border: 1px solid #ccc;
  }
  
  .ReactCompareSlider-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  .textboxafter {
    
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    top: -290px;
    left: 400px;
    box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5),
    0px 2px 4px rgba(0, 255, 255, 0.5), 0px 4px 8px rgba(0, 255, 255, 0.5),
    0px 8px 16px rgba(0, 255, 255, 0.5);
    background-color: #7e7edc;
  }

  .textboxbefore {
    max-width: 600px;
    
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    top: -610px;
    left: -400px;
    box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5),
    0px 2px 4px rgba(0, 255, 255, 0.5), 0px 4px 8px rgba(0, 255, 255, 0.5),
    0px 8px 16px rgba(0, 255, 255, 0.5);
    background-color: #7e7edc;
    
    
  
  }